export const topOverviewSlider = new Swiper('.js-topOverviewSlider.swiper', {
	centeredSlides: true,
	loop: true,
	loopAdditionalSlides: 1,
	speed: 1000,
	slidesPerView: 2,
	spaceBetween: 20,
	autoplay: {
		delay: 3000,
	},
	navigation: {
		nextEl: '.js-topOverviewSlider__next',
		prevEl: '.js-topOverviewSlider__prev',
	},
	on: {
		resize: function () {
			this.update();
			this.autoplay.start();
		},
	},
});


export const topFeatureSlider = new Swiper('.js-topFeatureSlider.swiper', {
	centeredSlides: true,
	loop: true,
	loopAdditionalSlides: 1,
	speed: 1000,
	slidesPerView: 1,
	spaceBetween: 100,
	autoplay: {
		delay: 3000,
	},
	navigation: {
		nextEl: '.js-topFeatureSlider__next',
		prevEl: '.js-topFeatureSlider__prev',
	},
	on: {
		resize: function () {
			this.update();
			this.autoplay.start();
		},
	},
});
